/**
 * Hack de datepicker pour permettre l'affichage am/pm de l'heure
 * Ce hack sera activé sur un a ou un A se trouve dans le format de la date.
 */
DatePickerPFS.implement({
	renderTime: function() {

		/**
		 * Initialisation des parametres necessaires au fonctionnement du am/pm
		 */
		var parameters = {maxHour : 24, ampm : false, isAm : false, minHour : 0};
		if (this.options.format.indexOf('a') >= 0 || this.options.format.indexOf('A') >= 0)
		{
			parameters.maxHour = 12;
			parameters.minHour = 1;
			parameters.ampm = true;
			parameters.isAm = this.d.getHours() < parameters.maxHour ? true : false;
		}
		
		var container = new Element('div', { 'class': 'time' }).inject(this.newContents);
		
		if (this.options.timePickerOnly) {
			this.picker.getElement('.titleText').set('text', 'Select a time');
		} else {
			this.picker.getElement('.titleText').set('text', this.format(this.d, 'j M, Y'));
		}
		
		var hoursToPut = (this.d.getHours() < parameters.maxHour ? this.d.getHours() : this.d.getHours() - parameters.maxHour);
		if (parameters.ampm && hoursToPut == 0)
			hoursToPut = parameters.maxHour;
		var elemHour = new Element('input', { type: 'text', 'class': 'hour' })
			.set('value', this.leadZero(hoursToPut))
			.addEvents({
				mousewheel: function(e) {
					var i = e.target, v = i.get('value').toInt();
					i.focus();
					if (e.wheel > 0) {
						if (parameters.ampm && v == parameters.maxHour - 1)
						{
							$$(".ampm").each(function (e) {e.removeClass('selected')});
							parameters.isAm = !parameters.isAm;
							e = (parameters.isAm ? elemAM : elemPM);
							e.addClass('selected');	
						}
						if (!parameters.ampm)
							v = (v < parameters.maxHour - 1) ? v + 1 : 0;
						else
							v = (v < parameters.maxHour) ? v + 1 : 1;
					} else {
						if (parameters.ampm && v == parameters.maxHour)
						{
							$$(".ampm").each(function (e) {e.removeClass('selected')});
							parameters.isAm = !parameters.isAm;
							e = (parameters.isAm ? elemAM : elemPM);
							e.addClass('selected');								
						}
						if (!parameters.ampm)
							v = (v > 0) ? v - 1 : parameters.maxHour - 1;
						else
							v = (v > 1) ? v - 1 : parameters.maxHour;
					}
					i.set('value', this.leadZero(v));
					//e.stop();
				}.bind(this)
			})
			.set('maxlength', 2)
			.inject(container);
			
		/**
		 * Application de la classe minutes_ampm si le mode am/pm est selectionne
		 * De plus l'attribut pitchMinute est utilise dans l'objet pour definir la facon dont defilent les minutes
		 */
		if (! this.options.minutePitch) this.options.minutePitch = 1;
		var elemMinute = new Element('input', { type: 'text', 'class': 'minutes' })
			.set('value', this.leadZero(this.d.getMinutes()))
			.addClass(parameters.ampm ? 'minutes_ampm' : '')
			.addEvents({
				mousewheel: function(e) {
					var i = e.target, v = i.get('value').toInt();
					i.focus();
					if (v % this.options.minutePitch)
						v = this.options.minutePitch * Math.round(v / this.options.minutePitch);
					if (e.wheel > 0) {
						if (v >= 60 - this.options.minutePitch)
						{
							elemHour.fireEvent('mousewheel', {'wheel' : 1, 'target':elemHour});
							i.focus();
						}
						v = (v < 60 - this.options.minutePitch) ? v + this.options.minutePitch : 0;
					} else {
						if (v < this.options.minutePitch)
						{
							elemHour.fireEvent('mousewheel', {'wheel' : -1, 'target':elemHour});
							i.focus();
						}
						v = (v >= this.options.minutePitch) ? v - this.options.minutePitch : 60 - this.options.minutePitch;
					}
					i.set('value', this.leadZero(v));
					e.stop();
				}.bind(this)
			})
			.set('maxlength', 2)
			.inject(container);
		/**
		 * Ajout des boutons am/pm si le mode am/pm est activé
		 */
		if (parameters.ampm)
		{
			var elemAM = new Element('div', {'class' : 'ampm', 'id' : 'ampm_am'})
				.addClass((parameters.isAm ? 'selected' : ''))
				.set('text', 'AM')
				.addEvents({
					click: function (e)
					{
						$$(".ampm").each(function (e) {e.removeClass('selected')});
						parameters.isAm = true;
						e.target.addClass('selected');
					}.bind(this)
				})
				.inject(container);
			
			var elemPM = new Element('div', {'class' : 'ampm', 'id' : 'ampm_pm'})
				.addClass((!parameters.isAm ? 'selected' : ''))
				.set('text', 'PM')
				.addEvents({
					click: function (e)
					{
						$$(".ampm").each(function (e) {e.removeClass('selected')});
						parameters.isAm = false;
						e.target.addClass('selected');
					}.bind(this)
				})
				.inject(container);
		}
		
		/**
		 * Ajout de la classe separator_ampm si le mode ampm est activé
		 */
		new Element('div', { 'class': 'separator' }).set('text', ':')
			.addClass(parameters.ampm ? 'separator_ampm' : '')
			.inject(container);
		
		new Element('input', { type: 'submit', value: 'OK', 'class': 'ok' })
			.addEvents({
				click: function(e) {
					e.stop();
					hours = this.picker.getElement('.hour').get('value').toInt();
					/**
					 * Modification de l'heure si le mode am/pm est activé et si le bouton pm est enclenché
					 */
					if (parameters.ampm)
					{
						if (hours == 12)
							hours = 0;
						if (!parameters.isAm)
							hours += parameters.maxHour;
					}
					this.select($merge(this.dateToObject(this.d), { hours: hours, minutes: this.picker.getElement('.minutes').get('value').toInt() }));
				}.bind(this)
			})
			.set('maxlength', 2)
			.inject(container);
	}
});

