/**
 * Hack mootools
 * La classe Request.HTML attend du XML qui ne peut contenir les entités html (&nbsp; etc)
 * Du coup on change en text/html au lieu de text/xml
 * Devrait être corrigé dans une future version de mootools
 */
Request.HTML.implement({

    processHTML: function(text){
        var match = text.match(/<body[^>]*>([\s\S]*?)<\/body>/i);
        text = (match) ? match[1] : text;

        var container = new Element('div');

        return $try(function(){
        var root = '<root>' + text + '</root>', doc;
            doc = new DOMParser().parseFromString(root, 'text/html');
            root = doc.getElementsByTagName('root')[0];
            for (var i = 0, k = root.childNodes.length; i < k; i++){
                var child = Element.clone(root.childNodes[i], true, true);
                if (child) container.grab(child);
            }
            return container;
        }) || container.set('html', text);
    }

});

/**
 * Hack du datepicker
 */
DatePicker.implement({
	/**
	 * Problème de compatibilité avec l'extension More (Date) de Mootools
	 */
	formatMinMaxDates: function() {
		if (this.options.minDate && $type(this.options.minDate) != 'date') {
			this.options.minDate = this.unformat(this.options.minDate.date, this.options.minDate.format);
		}
		if (this.options.maxDate && $type(this.options.maxDate) != 'date') {
			this.options.maxDate = this.unformat(this.options.maxDate.date, this.options.maxDate.format);
			this.options.maxDate.setHours(23);
			this.options.maxDate.setMinutes(59);
			this.options.maxDate.setSeconds(59);
		}
	},

	/**
	 * Correction du bug sur le 31 du mois
	 */
	unformat: function(t, format) {
		var d = new Date();
	
		// hack: Force le jour à 1 pour ne pas changer accidentellement de mois
		var today = d.getDate();
		d.setDate(1);

		var a = {};
		var c, m;
		t = t.toString();

		for (var i = 0; i < format.length; i++) {
			c = format.charAt(i);
			switch(c) {
				case '\\': r = null; i++; break;
				case 'y': r = '[0-9]{2}'; break;
				case 'Y': r = '[0-9]{4}'; break;
				case 'm': r = '0[1-9]|1[012]'; break;
				case 'n': r = '[1-9]|1[012]'; break;
				case 'M': r = '[A-Za-z]{'+this.options.monthShort+'}'; break;
				case 'F': r = '[A-Za-z]+'; break;
				case 'd': r = '0[1-9]|[12][0-9]|3[01]'; break;
				case 'j': r = '[1-9]|[12][0-9]|3[01]'; break;
				case 'D': r = '[A-Za-z]{'+this.options.dayShort+'}'; break;
				case 'l': r = '[A-Za-z]+'; break;
				case 'G':
				case 'H':
				case 'g':
				case 'h': r = '[0-9]{1,2}'; break;
				case 'a': r = '(am|pm)'; break;
				case 'A': r = '(AM|PM)'; break;
				case 'i':
				case 's': r = '[012345][0-9]'; break;
				case 'U': r = '-?[0-9]+$'; break;
				default:  r = null;
			}

			if ($chk(r)) {
				m = t.match('^'+r);
				if ($chk(m)) {
					a[c] = m[0];
					t = t.substring(a[c].length);
				} else {
					if (this.options.debug) alert("Fatal Error in DatePicker\n\nUnexpected format at: '"+t+"' expected format character '"+c+"' (pattern '"+r+"')");
					
					// hack: Rétablit la date du jour
					d.setDate(today);
					
					return d;
				}
			} else {
				t = t.substring(1);
			}
		}

		/**
		 * Il faut classer a pour que le traitement soit toujours dans l'ordre Année / mois / jour
		 * Sinon on part de la date du jour (exemple 13 sept 2010) on fixe le jour à 31 (31 sept => 01 oct)
		 * puis seulement après on fixe le mois => on se retrouve au 1er du mois
		 */
		var aSorted = {};
		var aTagOrder = ['y','Y','m','n','M','F','d','j','G','H','g','h','i','s','U'];
		for (var iTag = 0; iTag < aTagOrder.length; iTag ++) {
			for(c in a) {
		        if (c == aTagOrder[iTag]) {
		        	aSorted[c] = a[c];
		        }
		    }
		}

		for (c in aSorted) {
			var v = a[c];
			switch(c) {
				case 'y': d.setFullYear(v < 30 ? 2000 + v.toInt() : 1900 + v.toInt()); break; // assume between 1930 - 2029
				case 'Y': d.setFullYear(v); break;
				case 'm':
				case 'n': d.setMonth(v - 1); break;
				// FALL THROUGH NOTICE! "M" has no break, because "v" now is the full month (eg. 'February'), which will work with the next format "F":
				case 'M': v = this.options.months.filter(function(item, index) { alert('will return'); return item.substring(0,this.options.monthShort) == v }.bind(this))[0];
				case 'F': d.setMonth(this.options.months.indexOf(v)); break;
				case 'd':
				case 'j': previousday = v; d.setDate(v); break; // hack
				case 'G':
				case 'H': d.setHours(v); break;
				case 'g':
				case 'h':
					hours = v.toInt();
					if ((a['a'] == 'am' || a['A'] == 'AM') && hours == 12)
						hours = 0;
					else if ((a['a'] == 'pm' || a['A'] == 'PM') && hours != 12)
						hours += 12;
					d.setHours(hours);
				break;
				case 'i': d.setMinutes(v); break;
				case 's': d.setSeconds(v); break;
				case 'U': d = new Date(v.toInt() * 1000);
			}
		};
		return d;
	}

});


/**
 * Hack du datepicker
 */
DatePickerPFS.implement({
	/**
	 * Problème de compatibilité avec l'extension More (Date) de Mootools
	 */
	formatMinMaxDates: function() {
		if (this.options.minDate && $type(this.options.minDate) != 'date') {
			this.options.minDate = this.unformat(this.options.minDate.date, this.options.minDate.format);
		}
		if (this.options.maxDate && $type(this.options.maxDate) != 'date') {
			this.options.maxDate = this.unformat(this.options.maxDate.date, this.options.maxDate.format);
			this.options.maxDate.setHours(23);
			this.options.maxDate.setMinutes(59);
			this.options.maxDate.setSeconds(59);
		}
	},

	/**
	 * Correction du bug sur le 31 du mois
	 */
	unformat: function(t, format) {
		var d = new Date();

		// hack: Force le jour à 1 pour ne pas changer accidentellement de mois
		var today = d.getDate();
		d.setDate(1);

		var a = {};
		var c, m;
		t = t.toString();

		for (var i = 0; i < format.length; i++) {
			c = format.charAt(i);
			switch(c) {
				case '\\': r = null; i++; break;
				case 'y': r = '[0-9]{2}'; break;
				case 'Y': r = '[0-9]{4}'; break;
				case 'm': r = '0[1-9]|1[012]'; break;
				case 'n': r = '[1-9]|1[012]'; break;
				case 'M': r = '[A-Za-z]{'+this.options.monthShort+'}'; break;
				case 'F': r = '[A-Za-z]+'; break;
				case 'd': r = '0[1-9]|[12][0-9]|3[01]'; break;
				case 'j': r = '[1-9]|[12][0-9]|3[01]'; break;
				case 'D': r = '[A-Za-z]{'+this.options.dayShort+'}'; break;
				case 'l': r = '[A-Za-z]+'; break;
				case 'G':
				case 'H':
				case 'g':
				case 'h': r = '[0-9]{1,2}'; break;
				case 'a': r = '(am|pm)'; break;
				case 'A': r = '(AM|PM)'; break;
				case 'i':
				case 's': r = '[012345][0-9]'; break;
				case 'U': r = '-?[0-9]+$'; break;
				default:  r = null;
			}

			if ($chk(r)) {
				m = t.match('^'+r);
				if ($chk(m)) {
					a[c] = m[0];
					t = t.substring(a[c].length);
				} else {
					if (this.options.debug) alert("Fatal Error in DatePicker\n\nUnexpected format at: '"+t+"' expected format character '"+c+"' (pattern '"+r+"')");

					// hack: Rétablit la date du jour
					d.setDate(today);

					return d;
				}
			} else {
				t = t.substring(1);
			}
		}

		/**
		 * Il faut classer a pour que le traitement soit toujours dans l'ordre Année / mois / jour
		 * Sinon on part de la date du jour (exemple 13 sept 2010) on fixe le jour à 31 (31 sept => 01 oct)
		 * puis seulement après on fixe le mois => on se retrouve au 1er du mois
		 */
		var aSorted = {};
		var aTagOrder = ['y','Y','m','n','M','F','d','j','G','H','g','h','i','s','U'];
		for (var iTag = 0; iTag < aTagOrder.length; iTag ++) {
			for(c in a) {
		        if (c == aTagOrder[iTag]) {
		        	aSorted[c] = a[c];
		        }
		    }
		}

		for (c in aSorted) {
			var v = a[c];
			switch(c) {
				case 'y': d.setFullYear(v < 30 ? 2000 + v.toInt() : 1900 + v.toInt()); break; // assume between 1930 - 2029
				case 'Y': d.setFullYear(v); break;
				case 'm':
				case 'n': d.setMonth(v - 1); break;
				// FALL THROUGH NOTICE! "M" has no break, because "v" now is the full month (eg. 'February'), which will work with the next format "F":
				case 'M': v = this.options.months.filter(function(item, index) { alert('will return'); return item.substring(0,this.options.monthShort) == v }.bind(this))[0];
				case 'F': d.setMonth(this.options.months.indexOf(v)); break;
				case 'd':
				case 'j': previousday = v; d.setDate(v); break; // hack
				case 'G':
				case 'H': d.setHours(v); break;
				case 'g':
				case 'h':
					hours = v.toInt();
					if ((a['a'] == 'am' || a['A'] == 'AM') && hours == 12)
						hours = 0;
					else if ((a['a'] == 'pm' || a['A'] == 'PM') && hours != 12)
						hours += 12;
					d.setHours(hours);
				break;
				case 'i': d.setMinutes(v); break;
				case 's': d.setSeconds(v); break;
				case 'U': d = new Date(v.toInt() * 1000);
			}
		};
		return d;
	}

});

// override getStyle for getting around MooTools 1.3.2 issue on IE11
(function() {
    var originalGetStyle = Element.prototype.getStyle;
    Element.implement({
        getStyle: function(property) {
            var result = originalGetStyle.bind(this)(property);
            if (result === 'medium' && document.defaultView && document.defaultView.getComputedStyle) {
                result = document.defaultView.getComputedStyle(this, null).getPropertyValue(property);
            }
            return result;
        }
    });
}());
